module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Raleway","Archivo"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1549928971775768"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rhythmic Fusion Dance Company","short_name":"RF Dance","start_url":"/?source=pwa","background_color":"#000000","theme_color":"#b01e1d","display":"standalone","icon":"src/images/logo-512.png","include_favicon":true,"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"c98b2e4e3e70c07e4f3487d7564cc621"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-128535411-1"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
