// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-account-js": () => import("./../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-bachata-mondays-js": () => import("./../src/pages/bachata-mondays.js" /* webpackChunkName: "component---src-pages-bachata-mondays-js" */),
  "component---src-pages-bachata-thursdays-js": () => import("./../src/pages/bachata-thursdays.js" /* webpackChunkName: "component---src-pages-bachata-thursdays-js" */),
  "component---src-pages-center-js": () => import("./../src/pages/center.js" /* webpackChunkName: "component---src-pages-center-js" */),
  "component---src-pages-classes-js": () => import("./../src/pages/classes.js" /* webpackChunkName: "component---src-pages-classes-js" */),
  "component---src-pages-confirmation-js": () => import("./../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-events-js": () => import("./../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-online-js": () => import("./../src/pages/online.js" /* webpackChunkName: "component---src-pages-online-js" */),
  "component---src-pages-privates-js": () => import("./../src/pages/privates.js" /* webpackChunkName: "component---src-pages-privates-js" */),
  "component---src-pages-salsa-wednesdays-js": () => import("./../src/pages/salsa-wednesdays.js" /* webpackChunkName: "component---src-pages-salsa-wednesdays-js" */),
  "component---src-pages-sitemap-js": () => import("./../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-team-access-account-js": () => import("./../src/pages/team-access/account.js" /* webpackChunkName: "component---src-pages-team-access-account-js" */),
  "component---src-pages-team-access-classes-js": () => import("./../src/pages/team-access/classes.js" /* webpackChunkName: "component---src-pages-team-access-classes-js" */),
  "component---src-pages-team-access-documents-js": () => import("./../src/pages/team-access/documents.js" /* webpackChunkName: "component---src-pages-team-access-documents-js" */),
  "component---src-pages-team-access-index-js": () => import("./../src/pages/team-access/index.js" /* webpackChunkName: "component---src-pages-team-access-index-js" */),
  "component---src-pages-team-access-membership-js": () => import("./../src/pages/team-access/membership.js" /* webpackChunkName: "component---src-pages-team-access-membership-js" */),
  "component---src-pages-teams-bachata-js": () => import("./../src/pages/teams/bachata.js" /* webpackChunkName: "component---src-pages-teams-bachata-js" */),
  "component---src-pages-teams-js": () => import("./../src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */),
  "component---src-pages-teams-kids-js": () => import("./../src/pages/teams/kids.js" /* webpackChunkName: "component---src-pages-teams-kids-js" */),
  "component---src-pages-teams-ladies-js": () => import("./../src/pages/teams/ladies.js" /* webpackChunkName: "component---src-pages-teams-ladies-js" */),
  "component---src-pages-teams-men-js": () => import("./../src/pages/teams/men.js" /* webpackChunkName: "component---src-pages-teams-men-js" */),
  "component---src-pages-teams-salsa-js": () => import("./../src/pages/teams/salsa.js" /* webpackChunkName: "component---src-pages-teams-salsa-js" */),
  "component---src-pages-teams-training-team-js": () => import("./../src/pages/teams/training-team.js" /* webpackChunkName: "component---src-pages-teams-training-team-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-weekly-classes-js": () => import("./../src/pages/weekly-classes.js" /* webpackChunkName: "component---src-pages-weekly-classes-js" */),
  "component---src-pages-workshops-js": () => import("./../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

